@import url(sanitize.css);
h1, h2, h3, h4, h5, h6 {
  margin: 0;
}

a {
  color: #238;
  transition: 300ms ease-in;
}

a:hover, a:active {
  color: #4158cf;
}

.page-top header {
  position: relative;
  width: 100%;
  min-height: 200px;
  background: url("/app/img/header1.jpg");
  background-size: cover;
  background-position: center bottom;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.6);
}

.page-top header:before {
  content: "";
  position: absolute;
  top: 0;
  width: 100%;
  height: 50px;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0.4), transparent);
}

.page-top header h1 {
  position: absolute;
  margin: 0;
  line-height: 1;
  opacity: .9;
  color: #237;
  text-shadow: 2px 2px 4px rgba(255, 255, 255, 0.95);
}

.page-top .main-menu ul, .page-top .tabilog-menu ul {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
}

.page-top .main-menu ul li, .page-top .tabilog-menu ul li {
  text-align: center;
  border-radius: 10px;
}

.page-top .main-menu ul li a, .page-top .tabilog-menu ul li a {
  display: block;
  width: 100%;
  height: 100%;
  letter-spacing: 1.5px;
  text-decoration: none;
  transition: 100ms ease-in;
}

.page-top .main-menu ul li a:hover, .page-top .tabilog-menu ul li a:hover {
  background: rgba(255, 255, 255, 0.2);
}

.page-top .main-menu ul li {
  background: #238;
}

.page-top .main-menu ul li a {
  color: white;
}

.page-top .tabilog-menu h2 {
  margin: 0;
  text-align: center;
  font-size: 24px;
  line-height: 1;
}

.page-top .tabilog-menu ul li {
  background: #FF6600;
}

.page-top .tabilog-menu ul li a {
  color: white;
}

.page-top p, .page-top .links, .page-top .sub-menu {
  text-align: center;
}

@media (min-width: 641px) {
  .page-top header {
    height: 40vw;
    max-height: 600px;
  }
  .page-top header h1 {
    left: 80px;
    top: 50px;
    font-size: 60px;
  }
  .page-top .introduction {
    font-size: 18px;
    margin: 60px 40px 0;
  }
  .page-top .note {
    margin-top: 60px;
  }
  .page-top .main-menu, .page-top .tabilog-menu {
    margin: 60px auto 0;
    width: 660px;
  }
  .page-top .main-menu ul, .page-top .tabilog-menu ul {
    display: flex;
    flex-wrap: wrap;
    width: 660px;
  }
  .page-top .main-menu ul li, .page-top .tabilog-menu ul li {
    margin-bottom: 20px;
    width: 320px;
    height: 60px;
  }
  .page-top .main-menu ul li:nth-child(even), .page-top .tabilog-menu ul li:nth-child(even) {
    margin-left: 20px;
  }
  .page-top .main-menu ul li a, .page-top .tabilog-menu ul li a {
    line-height: 60px;
    letter-spacing: 1.5px;
  }
  .page-top .links, .page-top .sub-menu {
    margin-top: 40px;
    text-align: center;
  }
  .page-top .links li, .page-top .sub-menu li {
    margin-top: 10px;
  }
  .page-top .links li:first-child, .page-top .sub-menu li:first-child {
    margin-top: 0;
  }
  .page-top .links li a, .page-top .sub-menu li a {
    text-decoration: none;
  }
}

@media (max-width: 640px) {
  .page-top header {
    border-top: 10px solid #238;
  }
  .page-top header h1 {
    right: 20px;
    top: 20px;
    font-size: 30px;
  }
  .page-top .introduction {
    font-size: 14px;
    line-height: 1.4;
    margin: 40px 0 0;
    text-align: center;
  }
  .page-top .main-menu, .page-top .tabilog-menu {
    margin-top: 30px;
  }
  .page-top .main-menu ul, .page-top .tabilog-menu ul {
    width: 100%;
  }
  .page-top .main-menu ul li, .page-top .tabilog-menu ul li {
    margin-bottom: 15px;
    width: 100%;
    height: 50px;
  }
  .page-top .main-menu ul li a, .page-top .tabilog-menu ul li a {
    line-height: 50px;
    letter-spacing: 1.5px;
  }
  .page-top .note {
    padding-left: 1em;
    margin-top: 30px;
  }
  .page-top .note li {
    margin-top: 10px;
    font-size: 12px;
    line-height: 1.4;
  }
  .page-top .note li:first-child {
    margin-top: 0;
  }
  .page-top .links, .page-top .sub-menu {
    margin-top: 30px;
  }
  .page-top .links li, .page-top .sub-menu li {
    margin-top: 5px;
  }
  .page-top .links li:first-child, .page-top .sub-menu li:first-child {
    margin-top: 0;
  }
}
